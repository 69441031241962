import React, { useState } from "react";
import { motion } from "framer-motion";
import About from "./About";

const faqList = [
  {
    id: "1",
    question: "CAN I EXTEND MY STAY?",
    answer: (
      <>
        Please indicate any additional nights needed and our team will request
        these with the hotel and contact you once they may be confirmed. Please
        note the group rate is not guaranteed, rooms are subject to availability
        and a credit card will be required upon check in for any incidental
        charges and/or an additional night’s stay.
      </>
    ),
  },
  {
    id: "3",
    question: "IS THERE TRANSPORTATION FROM THE AIRPORT?",
    answer: (
      <>
        Yes. VIP transportation will be scheduled on your behalf. More details
        on your arrangements will be provided closer to the event date.
      </>
    ),
  },
  {
    id: "4",
    question: "WHEN DO I NEED TO ARRIVE / DEPART?",
    answer: (
      <>
        You will want to arrive at the resort on Wednesday, April 30th by 12:00
        PM, and plan to depart on Friday, May 2nd after 12:00 PM. Event
        organizers will work with you to make travel arrangements on your
        behalf.
      </>
    ),
  },
  {
    id: "5",
    question: "IS THIS RESORT ALL-INCLUSIVE?",
    answer: (
      <>
        No, this resort does not offer an all-inclusive package. However, all
        food, drinks, and activities that are a part of the event agenda will be
        covered by ZipRecruiter.
      </>
    ),
  },
  {
    id: "6",
    question:
      "UPON CHECK-IN, DO I NEED TO PUT A CREDIT CARD ON FILE FOR INCIDENTALS?",
    answer: (
      <>
        Yes, you will need to provide a credit card at check-in for any
        incidental charges you may incur, above and beyond the $300 resort
        credit that will be provided by ZipRecruiter.
      </>
    ),
  },
  {
    id: "7",
    question: "CAN I RECEIVE FREQUENT FLYER MILES FOR THIS TRIP?",
    answer: (
      <>
        Yes. When completing the air portion of this registration, you will be
        asked to enter your preferred airline along with a frequent flyer
        number. Our travel partner will work with you to discuss using your
        miles options with you directly.
      </>
    ),
  },
  {
    id: "8",
    question: "ARE BAGGAGE FEES INCLUDED?",
    answer: (
      <>
        ZipRecruiter does not cover your baggage fees. This will depend on the
        carrier you fly and how many bags you are allowed. Once your flights
        have been booked, please check with your carrier on their baggage fee
        policies.
      </>
    ),
  },
  {
    id: "9",
    question: "WHAT IF I MISS A FLIGHT OR THERE ARE FLIGHT DELAYS?",
    answer: (
      <>
        We’ll provide contract details for our travel partner closer to the
        event day as it may pertain to flight delays and schedules.
      </>
    ),
  },
  // {
  //   id: "10",
  //   question: "WHAT SHOULD I PACK?",
  //   answer: (
  //     <>
  //       Resort wear will be appropriate for all program events including the
  //       scheduled hosted functions. Be sure to pack comfortable footwear, a
  //       swimsuit, a cap or sunhat, sunglasses, and appropriate clothing for a
  //       variety of activities, both professional and leisure.
  //     </>
  //   ),
  // },
  {
    id: "10",
    question: "WHAT IS THE DRESS CODE FOR THE HOSTED EVENTS?",
    answer: <>The recommended attire is Resort Casual.</>,
  },
  {
    id: "11",
    question: "DIETARY RESTRICTIONS",
    answer: (
      <>
        Please indicate any dietary restrictions within the registration form.
      </>
    ),
  },
  // {
  //   id: "12",
  //   question: "CAN I BRING A GUEST/SPOUSE?",
  //   answer: (
  //     <>
  //       You are welcome to bring a guest/spouse with you, but travel, food, and
  //       drink expenses for guests will not be covered by ZipRecruiter.
  //     </>
  //   ),
  // },
];
export default function Faq() {
  const [isActive, setIsActive] = useState(null);
  return (
    <>
      <section className="info">
        <motion.div
          animate={{
            x: [-50, 0],
            opacity: [0, 1],
          }}
          transition={{ duration: 0.8 }}
        >
          <div className="auto__container">
            <div className="info__inner agenda-info">
              <div className="info__inner-title">
                <h2>FREQUENTLY ASKED QUESTIONS</h2>
                {/* <p>
                  Below, please find a high-level agenda of activities and
                  events. Check back for updates!
                </p> */}
              </div>
              <div className="info__inner-main">
                {faqList.map((item, index) => {
                  return (
                    <InfoItem
                      itemData={item}
                      key={index}
                      isActive={isActive}
                      setIsActive={setIsActive}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </motion.div>
        <div className="bottom-button-container faq">
          <a
            href="https://cvent.me/X5gdVz"
            target="_blank"
            className="button primary"
          >
            REGISTER NOW
          </a>
        </div>
      </section>
      <About />
    </>
  );
}
const InfoItem = ({ itemData, isActive, setIsActive }) => {
  return (
    <div className="infoItem">
      <div
        onClick={() => {
          if (isActive?.id === itemData.id) {
            setIsActive(null);
          } else {
            setIsActive(itemData);
          }
        }}
        className={
          "infoItem__head " + (isActive?.id === itemData.id ? "active" : "")
        }
      >
        <h5>{itemData.question}</h5>
      </div>
      <div
        className={
          "infoItem__body " + (isActive?.id === itemData.id ? "active" : "")
        }
      >
        <p className="sm">{itemData.answer}</p>
      </div>
    </div>
  );
};
