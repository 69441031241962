import React from "react";
import Hero from "./components/Hero";
import Expect from "./components/Expect";
import Themes from "./components/Themes";
import About from "./components/About";
import Company from "./components/Company";

export default function Home() {
  return (
    <>
      <Hero />
      <Themes />
      {/* <Company />
      <Expect /> */}
      {/* <About /> */}
    </>
  );
}
