import React from "react";
import { motion } from "framer-motion";

export default function Info() {
  return (
    <section class="info">
      <div class="auto__container">
        <div class="info__inner agenda-info">
          <motion.div
            animate={{
              x: [50, 0],
              opacity: [0, 1],
            }}
            transition={{ duration: 0.8, ease: "easeOut", delay: 0.1 }}
          >
            <div class="info__inner-title">
              <h2>AGENDA</h2>
              <p>
                Below, please find a high-level agenda of activities and events
                from the 2024 Leadership Summit. Check back for updates!
              </p>
            </div>
          </motion.div>

          <div class="info__inner-main">
            <div class="infoContent">
              <motion.div
                animate={{
                  x: [50, 0],
                  opacity: [0, 1],
                }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.1 }}
              >
                <h4>Day 1 | April 17, 2024</h4>
                <ul>
                  <li>
                    <div className="time">2:00 PM</div>
                    <div className="info-container">
                      <p>Light Snacks & Beverages</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">3:00 PM</div>
                    <div className="info-container">
                      <p>Welcome Remarks & Group Introductions</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">4:00 PM</div>
                    <div className="info-container">
                      <p>Roundtable Exercise: Talent Acquisition in 2024</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">6:00 PM</div>
                    <div className="info-container">
                      <p>Happy Hour</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">7:00 PM</div>
                    <div className="info-container">
                      <p>Dinner</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">9:00 PM</div>
                    <div className="info-container">
                      <p>Evening Activity: Casino Night</p>
                    </div>
                  </li>
                </ul>
              </motion.div>
            </div>
            <div class="infoContent">
              <motion.div
                animate={{
                  x: [50, 0],
                  opacity: [0, 1],
                }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.2 }}
              >
                <h4>Day 2 | April 18, 2024</h4>
                <ul>
                  <li>
                    <div className="time">8:00 AM</div>
                    <div className="info-container">
                      <p>Breakfast</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">9:00 AM</div>
                    <div className="info-container">
                      <p>
                        Securing Talent Acquisition’s Seat at the Table:
                        Conversation with Dave Travers & Brie Carere
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="time">9:45 AM</div>
                    <div className="info-container">
                      <p>
                        Market Watch: H2 Projections & Winning Recruitment
                        Tactics
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="time">11:45 AM</div>
                    <div className="info-container">
                      <p>Lunch</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">1:15 PM</div>
                    <div className="info-container">
                      <p>
                        Talent Acquisition Strategy: How to Speak in Terms of
                        Revenue, Retention, and ROI
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="time">2:30 PM</div>
                    <div className="info-container">
                      <p>Networking Activity (Spa or Golf)</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">6:00 PM</div>
                    <div className="info-container">
                      <p>Happy Hour</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">7:00 PM</div>
                    <div className="info-container">
                      <p>Dinner</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">8:00 PM</div>
                    <div className="info-container">
                      <p>Evening Activity</p>
                    </div>
                  </li>
                </ul>
              </motion.div>
            </div>
            <div class="infoContent">
              <motion.div
                animate={{
                  x: [50, 0],
                  opacity: [0, 1],
                }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 0.3 }}
              >
                <h4>Day 3 | April 19, 2024</h4>
                <ul>
                  <li>
                    <div className="time">8:00 AM</div>
                    <div className="info-container">
                      <p>Breakfast</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">9:00 AM</div>
                    <div className="info-container">
                      <p>You’re Recruiting Modern Job Seekers Wrong</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">10:15 AM</div>
                    <div className="info-container">
                      <p>Closing Session</p>
                    </div>
                  </li>
                  <li>
                    <div className="time">11:30 AM</div>
                    <div className="info-container">
                      <p>Lunch</p>
                    </div>
                  </li>
                </ul>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-button-container info">
        <a
          href="https://cvent.me/X5gdVz"
          target="_blank"
          className="button primary"
        >
          REGISTER NOW
        </a>
      </div>
    </section>
  );
}
